<template>
  <!-- 这是面包屑 -->
  <div class="crumbox">
    <el-breadcrumb>
      <span class="text">您当前的位置：</span>
      <el-breadcrumb-item v-for="(item, index) in list" :key="index">
        <span
          :class="{ back: index != list.length - 1 }"
          @click="tolink(index, item.path, item.query)"
          >{{ item.name }}</span
        >
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: "crumbs",
  props: {
    list: {
      typeof: [Array],
    },
  },
  methods: {
    // 点击进行跳转
    tolink(index, path, query) {
      if (index != this.list.length - 1) {
        this.until.jump.call(this, path, "push", query);
      }
    },
  },
};
</script>


<style scoped lang="less">
@media screen and (min-width: 1000px) {
  // 当前页面
  .crumbox {
    margin: 0.625rem 0 0.9375rem;
    color: #515151;
    background-color: #f2f2f2;
    height: 2.1875rem;
    // 左边的提示
    .text {
      font-size: 0.875rem;
    }
    .back {
      color: #000000;
      font-weight: 550;
      cursor: pointer;
    }
    // 可点击的
    .back:hover {
      color: #416bf5;
    }
    // 面包屑
    /deep/.el-breadcrumb {
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 0.625rem;
      font-size: 0.875rem;
      // 分割符号
      .el-breadcrumb__separator {
        margin: 0 0.625rem;
      }
    }
  }
}

@media screen and (max-width: 999px) {
  // 当前页面
  .crumbox {
    color: #515151;
    background-color: #f2f2f2;
    height: 1.5rem;
    // 左边的提示
    .text {
      display: none;
    }
    .back {
      color: #000000;
      font-weight: 550;
      cursor: pointer;
    }
    // 可点击的
    .back:active {
      color: #416bf5;
    }
    // 面包屑
    /deep/.el-breadcrumb {
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 0.625rem;
      font-size: 0.875rem;
      // 分割符号
      .el-breadcrumb__separator {
        margin: 0 0.625rem;
      }
    }
  }
}
</style>
