<template>
  <!-- 信息公告代码 -->
  <div class="toolDetail">
    <!--
    <div id="homebox" class="crumb">
      <div id="pagebox">
        
      </div>
    </div>
     -->
     <crumbs :list="crumblist" />
    <html-frame :url = "toolUrl" :name="toolName" v-if="loaded" />
    <div v-else> 正在加载数据</div>
  </div>
</template>

<script>

import crumbs from "@/components/all/crumbs"; //引入导航栏组件

import htmlFrame from "@/components/all/toolHtmlFrame"; //引入尾部组件
let appkey="skdhkjbkb12o1hz";

export default {
  name: 'ToolDetail',
  components: {
    htmlFrame,

    crumbs, //引入导航栏组件

  },

  data() {
    return {
      loaded:false,
      uid:'',
      username: '',
      tool: {},
      toolUrl: '',
      id: '',
      toolName: '',
      primary: 1, //一级菜单的选中样式
      istrue: true, //刷新页面
      crumblist: [
        {
          name: "首页",
          path: "/home",
          query: {},
        },
        {
          name: "在线工具",
          path: "/inspect/tool/",
          query: {},
        },
        {
          name: "工具详细信息",
          path: "",
          query: {},
        },
      ],
    }
  },
  
  created() {
    let checkLogin = this.until.checkLogin();
    // console.log(" check login ", checkLogin)
    if(!checkLogin) {
      let path = this.$route.path
      // console.log(" !checkLogin  , jumpt /tologin ", !checkLogin)
      this.until.jump.call(this,'/tologin', "push", {title:"在线检测工具", redirect:path});
    } else {
      console.log(" userinfo", this.until.getUserInfo())
      this.uid = this.until.getUserInfo().id
      this.username = this.until.getUserInfo().username
      this.init()
      this.getdata()
    }
    // this.init()
    // this.getdata();
    console.log(" ====== uid:", this.uid)
    console.log(" tool url ", this.toolUrl)
  },
  methods: {
    init(){
      this.id = this.$route.params.id
      console.log("   standard id " ,   this.id)
    },
    getdata() {
      this.$api.getToolDetail(this.id).then((res) => {
        console.log( res.data)
        this.tool = res.data;
        this.toolName = this.tool.name
        // this.crumblist[2].name =  this.toolName
        console.info(" ===============  this.tool.name " + this.tool.name);
        console.info(" ===============  this.tool.toolUrl " + this.tool.toolUrl);
        
        if(this.tool.name=="机器学习算法能力评估系统"){
          this.toolUrl = this.evalTool( this.tool.toolUrl)
        } else {
          this.toolUrl = this.tool.toolUrl + this.uid
        }
        this.loaded = true
        // console.log(" =======----------- toolUrl:",this.toolUrl)
      });
    },
    //机器学习评估工具
    evalTool(url){
      //appkey: skdhkjbkb12o1hz
      // username
      // 户名		是	q450206137
      // ts	当前时间戳		是	
      // sign	签名		是	md5(username+ts+appkey)
      // http://192.168.1.124/?username=1&ts=123&sign=fe882a5ced1543079dad5af38c8799bd
      let username = this.username
      let ts = "0"
      // username = "q450206137"
      // ts = "123"
      // appkey="skdhkjbkb12o1hz";
      let sign= this.$md5( username + ts +  appkey)
      let detailUrl = url + "?username=" + username +"&ts="+ts +"&sign=" +sign;
      console.log(detailUrl);
      return detailUrl
    }
  },
};
</script>

<style scoped lang="less">

.toolDetail{
 
  box-sizing: border-box;
  
}

</style>

